<template>
    <!-- Page: pages/contact-travels -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <h1 v-if="contact">Travel History for {{ contact.firstName }} {{ contact.middleName }} {{ contact.lastName }}</h1>
        <preloader v-if="travelsLoading"/>
        <general-container
            custom-class="travel-history-block" :isLocked="false"
            v-if="travels && travels.length > 0"
        >
            <info-block-travel
                v-for="travel in travels"
                :key="travel.contentId"
                :dateFrom="travel.startDate && travel.startDate.format"
                :dateTo="travel.endDate && travel.endDate.format"
                :destination="travel.destination"
                :event="travel.purpose"
                :cost="travel.totalCost ? ('$' + travel.totalCost) : null"
            />
            <pagination :current="page" :page-count="pageCount" v-on:paginated="paginate($event)" v-if="pageCount > 1"/>
        </general-container>
    </div>
    <!-- End Page: pages/contact-travels -->
</template>

<script>
    import ContactInformationCard from "./contact-info-card";
    import TravelHistoryBlock from "./travel-history-block";
    import Alert from "./alert";
    import Breadcrumbs from "./breadcrumbs";
    import GeneralContainer from "./general-container";
    import InfoBlockTravel from "./info-block--travel";
    import Preloader from "@/alivue/components/preloader";
    import { useRoute } from "vue-router";
    import { computed, onMounted, reactive, ref } from "vue";
    import PageHandler from "@/handler/PageHandler";
    import RequestHandler from "@/handler/RequestHandler";
    import Pagination from "./pagination";
    import SearchBlock from "./search-block";
    import { useStore } from "vuex";

    export default {
        components: {
            Pagination,
            Preloader,
            InfoBlockTravel,
            GeneralContainer, Breadcrumbs, Alert, TravelHistoryBlock, ContactInformationCard,
            SearchBlock,
        },
        props: ['initialContact'],
        setup(props) {
            const path = reactive([]);
            const itemsPerPage = 10;

            const route = useRoute();
            const contact = ref(null);
            const travelsLoading = ref(true);
            const travels = ref([]);
            const page = ref(1);
            const totalTravelsCount = ref(null);
            const pageCount = computed(() => totalTravelsCount.value && Math.ceil(totalTravelsCount.value / itemsPerPage));
            let paginationLoaded = false;

            const contactLoaded = (data) => {
                contact.value = data;

                if (data.agency && data.agency.name) {
                    path.push({url: data.agency.url, title: data.agency.name});
                }
                if (data.position && data.position.title) {
                    path.push({url: data.position.url, title: data.position.title});
                }
                path.push({url: data.url, title: data.firstName + ' ' + data.lastName});
                path.push({title: 'Travel History'});

                loadContactTravels(data.contentId)
            };

            const loadPagination = (contactId) => {
                RequestHandler.loadContactTravelsCount(contactId)
                    .then(count => totalTravelsCount.value = count)
                    .catch(error => alert(error));
            };

            const loadContactTravels = (contactId) => {
                travelsLoading.value = true;
                travels.value = [];
                RequestHandler.loadContactTravels(contactId, itemsPerPage, (page.value - 1) * itemsPerPage)
                    .then(({items, hasNextPage}) => {
                        travelsLoading.value = false;
                        travels.value = items;
                        if (hasNextPage && !paginationLoaded) {
                            paginationLoaded = true;
                            loadPagination(contactId);
                        }
                    })
                    .catch(error => {
                        travelsLoading.value = false
                        alert(error);
                    });
            };

            const paginate = function(pageTo) {
                page.value = pageTo;
                loadContactTravels(contact.value.contentId);
            };

            onMounted(() => {
                if (props.initialContact) {
                    contactLoaded(props.initialContact);
                    return;
                }
                RequestHandler.loadPage(route.params.contactUrl)
                    .then(response => {
                        PageHandler.updatePageHead(response);
                        contactLoaded(response.data);
                    })
                    .catch(error => alert(error));
            });

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                path,
                pageCount,
                page,
                contact,
                paginate,
                travelsLoading,
                travels,
                showSearch,
            };
        },
    }
</script>
